import React, { useEffect, memo } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import styled from '@emotion/styled';
import { breakpoints, color, wrapper1200 } from '../../../utils/style';
import Img from 'gatsby-image';

const SliderWrap = styled('div')`
  ${wrapper1200}
  position: relative;
  [class^="number-slide"],
  [class*=" number-slide"] {
    background: ${color.black};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    border-radius: 24px;
    width: 100%;
    max-width: 100%;
    height: 465px;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      min-width: 100%;
    }
  }
  .navigation-wrapper {
    position: relative;
  }
  .dots {
    display: flex;
    padding: 12px 0;
    justify-content: center;
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
  }
  .dot {
    border: none;
    width: 32px;
    height: 8px;
    background: ${color.dotsSlider};
    border-radius: 8px;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
    &:hover {
      background: ${color.activeDotSlider};
    }
  }
  .dot:focus {
    outline: none;
  }
  .dot.active {
    background: ${color.activeDotSlider};
  }
  .arrow {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #fff;
    cursor: pointer;
  }
  .arrow--left {
    left: 5px;
  }
  .arrow--right {
    left: auto;
    right: 5px;
  }
  .arrow--disabled {
    fill: rgba(255, 255, 255, 0.5);
  }
  @media (max-width: ${breakpoints.lg}) {
    [class^="number-slide"],
    [class*=" number-slide"] {
      height: 360px;
    }
  }
  @media (max-width: ${breakpoints.tl}) {
    [class^="number-slide"],
    [class*=" number-slide"] {
      height: 350px;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    [class^="number-slide"],
    [class*=" number-slide"] {
      height: auto;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .dots {
      display: none;
    }
    [class*=" number-slide"] {
      min-height: 259px;
    }
  }
`;

const TextBlock = styled('div')`
  width:100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-height: 100%;
  overflow: hidden;
  .desktop-wrapper {
    max-width: 1150px;
    padding: 0 25px;
    margin: 0 auto;
    text-align: left;
  }
  .h1 {
    margin-bottom: 4px;
    font-size: 52px;
    font-weight: bold;
    color: ${color.orange};
    text-transform: uppercase;
  }
  .p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  .slide-wrap {
    display: block;
  }
  @media (max-width: ${breakpoints.lg}) {
    .h1 {
      font-size: 2rem;
      margin: 0 0 1rem;
    }
    .p {
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .desktop-wrapper {
      padding: 0 20px;
    }
    .h1 {
      font-size: 1.4rem;
      margin-top: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .p {
      font-size: 0.8rem;
      height: 18px;
      overflow: hidden;
    }
    .button {
      padding: 12px 20px;
    }
  }
  
  @media (max-width: ${breakpoints.xs}) {
    top: 48%;
    .h1 {
      margin: 0;
    }
    .p {
      margin-bottom: 8px;
    }
    .button {
      padding: 10px 20px;
      height: 36px;
    }
  }
  
`;

const KeenSlider = ({keenSlider}) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [pause, setPause] = React.useState(false);
  const timer = React.useRef();
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
    loop: true,
    duration: 1000,
    slidesPerView: 1,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
  });

  useEffect(() => {
    sliderRef.current.addEventListener("mouseover", () => {
      setPause(true)
    });
    sliderRef.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [sliderRef]);

  useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 5000);
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider]);

  return (
    <SliderWrap>
      <div className="navigation-wrapper">
        <div ref={sliderRef} className="keen-slider">
          {
            keenSlider.map((item, index) => {
              const link = item.acf ? item.acf.slide_url : '';
              const { childImageSharp = {} } = item.localFile || {};
              const line1 = item.acf ? item.acf.slide_title : '';
              const line2 = item.acf ? item.acf.slide_description : '';
              const ext = item.acf && item.acf.slide_url.indexOf('http') !== -1;
              const anchor = item.acf && item.acf.slide_url.indexOf('#') !== -1;

              return (
                !link ?
                  <div className={`keen-slider__slide number-slide${index + 1}`} key={item.path}>
                    <Img fluid={childImageSharp.fluid} alt={item.alt_text ? item.alt_text : 'slide'} fadeIn={false}
                         loading='eager'/>
                    {(line1 || line2) &&
                    <TextBlock className="text-block">
                      <div className="desktop-wrapper">
                        {line1 &&
                        <div className="h1">{line1}</div>
                        }
                        {line2 &&
                        <div className="p">{line2}</div>
                        }
                      </div>
                    </TextBlock>
                    }
                  </div>
                  : <a className={`keen-slider__slide number-slide${index + 1}`}
                       href={(ext || anchor) ? link : `${process.env.URL}` + link} key={item.path}>
                    <Img fluid={childImageSharp.fluid} alt={item.alt_text ? item.alt_text : 'slide'} fadeIn={false} loading='eager'/>
                    {(line1 || line2) &&
                    <TextBlock className="text-block">
                      <div className="desktop-wrapper">
                        {line1 &&
                        <div className="h1">{line1}</div>
                        }
                        {line2 &&
                        <div className="p">{line2}</div>
                        }
                        <span className="button gradient-button">คลิกเลย</span>
                      </div>
                    </TextBlock>
                    }
                  </a>
              )
            })
          }
        </div>

        {/*If you need arrows*/}

        {/*{slider && (*/}
        {/*<>*/}
        {/*<ArrowLeft*/}
        {/*onClick={(e) => e.stopPropagation() || slider.prev()}*/}
        {/*disabled={currentSlide === 0}*/}
        {/*/>*/}
        {/*<ArrowRight*/}
        {/*onClick={(e) => e.stopPropagation() || slider.next()}*/}
        {/*disabled={currentSlide === slider.details().size - 1}*/}
        {/*/>*/}
        {/*</>*/}
        {/*)}*/}
      </div>
      {slider && (
        <div className="dots">
          {[...Array(slider.details().size).keys()].map((idx) => {
            return (
              <button
                key={idx}
                aria-label="Slider dot"
                name="Slider dot"
                onClick={() => {
                  slider.moveToSlideRelative(idx)
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              />
            )
          })}
        </div>
      )}
    </SliderWrap>
  )
};

export default memo(KeenSlider);

//If you need arrows

// function ArrowLeft(props) {
//   const disabeld = props.disabled ? " arrow--disabled" : ""
//   return (
//     <svg
//       onClick={props.onClick}
//       className={"arrow arrow--left" + disabeld}
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 24 24"
//     >
//       <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/>
//     </svg>
//   )
// }
//
// function ArrowRight(props) {
//   const disabeld = props.disabled ? " arrow--disabled" : ""
//   return (
//     <svg
//       onClick={props.onClick}
//       className={"arrow arrow--right" + disabeld}
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 24 24"
//     >
//       <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>
//     </svg>
//   )
// }
