import React, { memo } from "react";
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { breakpoints, color } from '../../../utils/style';
import Img from 'gatsby-image';

const PopularGamesWrap = styled('div')`
  margin: 50px 0;
  .h1 {
    margin-bottom: 2rem;
  }
  @media (max-width: ${breakpoints.sm}) {
    margin: 50px 0 0;
    .h1 {
      margin-bottom: 0.7rem;
    }
    .button {
      margin: 30px 0 10px 10px;
    }
  }
`;

const GalleryImages = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;
  .gallery-item {
    margin-bottom: 2%;
    flex-basis: 32%;
    &:hover, &:active {
      text-decoration: none;
      color: ${color.white};
      border: none;
      .button-container {
        background: rgba(0,0,0,0.8);
      }
      .button {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    .gallery-item {
      flex-basis: 48%;
    } 
  }
  @media (max-width: ${breakpoints.sm}) {
    padding: 0 5px;
    .gallery-item {
      flex-basis: 100%;
    } 
  }
`;

const GalleryImage = styled('div')`
  .image-container {
    position: relative;
    border-radius: 10px;
  }
  .button-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 20px;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    border-radius: 10px;
  }
  .gatsby-image-wrapper {
    border-radius: 10px;
  }
  .button {
    width: fit-content;
    text-transform: uppercase;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    margin: 0 auto -20px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  .image-desc {
    padding: 0.5em 0;
    width: 100%;
    opacity: 0.8;
    font-size: 14px;
    strong { 
      margin-right: 10px;
    }
  }
  
  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
    .image-desc {
      font-size: 14px;
      margin: 10px 0 20px;
    }
  }
`;
const PopularGames = ({ popular_games }) => {
  return (
    <PopularGamesWrap>
      <h2 className="h1 rich-design a-center">
        <span>เกมเด็ดยอดนิยม</span>
      </h2>
      <GalleryImages>
        {popular_games.map((item) => {
            return (
              <a className="gallery-item" href={item.acf.slide_url} target="_blank" rel="noreferrer"  key={'popular-game-slide-' + item.id}>
                <GalleryImage>
                  <div className="image-container">
                    {item.localFile?.childImageSharp?.fluid &&
                    <Img fluid={item.localFile.childImageSharp.fluid} alt={item.alt_text}
                         fadeIn={false} loading='eager'/>
                    }
                    <div className="button-container">
                      <span className="button gradient-button">อ่านต่อ</span>
                    </div>
                  </div>
                  {(item.acf.slide_title || item.acf.slide_description) &&
                  <div className="wrapper-image-desc">
                    <div className="image-desc">
                      {item.acf.slide_title &&
                      <strong>
                        <span>{item.acf.slide_title}</span>
                      </strong>
                      }
                      {item.acf.slide_description &&
                      <span>{item.acf.slide_description}</span>
                      }
                    </div>
                  </div>
                  }
                </GalleryImage>
              </a>
            )
          }
        )}
      </GalleryImages>
    </PopularGamesWrap>
  )
};
export default memo(PopularGames);

PopularGames.propTypes = {
  isHome: PropTypes.bool,
};
