import React from "react"
import { graphql } from "gatsby"
import {
  breakpoints,
  color,
  ContentStyles,
  wrapper,
  ContentStylesExt,
} from "../utils/style"
import Layout from "../components/shared/layout"
import SEO from "../components/SEO/SEO"
import styled from "@emotion/styled"
import GetWindowWidth from "../components/shared/GetWindowWidth"
import KeenSlider from "../components/shared/KeenSlider"
import LiveOnlineCasinos from "../components/Content/Home/LiveOnlineCasinos"
import StepsNew from "../components/Content/Home/StepsNew"
import StepsNewMobile from "../components/Content/Home/StepsNewMobile"
import HowToPlay from "../components/Content/Home/HowToPlay"
import HowToPlayMobile from "../components/Content/Home/HowToPlayMobile"
import WhyChooseUs from "../components/Content/Home/WhyChooseUs"
import AgentPromotion from "../components/Content/Home/AgentPromotion"
import PopularGames from "../components/Content/Home/PopularGames"

const WrapperDesktop = styled("div")`
  ${wrapper}
`

const ContentWrapper = styled("div")`
  margin-bottom: 50px;
  ${ContentStyles}
  ${ContentStylesExt}
  text-align: center;
  background: rgba(22, 1, 1, 0.8);
  max-height: 280px;
  overflow-y: scroll;
  padding: 0 15px;
  h2,
  h3 {
    color: ${color.white};
    font-weight: bold;
    font-size: 40px;
    line-height: 42px;
  }
  .a-left {
    margin-bottom: 15px;
  }
  .show-more {
    .more-span {
      display: inline;
    }
    .less-span {
      display: none;
    }
  }
  .show-less {
    .more-span {
      display: none;
    }
    .less-span {
      display: inline;
    }
  }
  .home-text-content {
    font-size: 14px;
    line-height: 1.62;
    text-align: left;
  }
  .full-height {
    max-height: 50000px;
  }
  .home-image-flex {
    svg {
      width: 150px;
    }
    .col-left {
      flex: 1;
      p {
        margin-top: 0;
      }
    }
    .col-right {
      flex: 0;
      padding-left: 20px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .home-text-content {
      margin-bottom: 20px;
      max-height: 585px;
    }
    .full-height {
      max-height: 50000px;
    }
    .home-image-flex {
      .col-left,
      .col-right {
        flex: auto;
      }
      .col-right {
        text-align: center;
      }
    }
  }
`

const HomePage = ({ data }) => {
  const windowWidth = GetWindowWidth()
  const isDesktop = windowWidth > 600
  const homePageNode = data.allWordpressPage.edges[0].node
  const metaTitle =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_title
      ? homePageNode.yoast_meta.yoast_wpseo_title
      : homePageNode.title + process.env.OWNER
  const metaDesc =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_metadesc
      ? homePageNode.yoast_meta.yoast_wpseo_metadesc
      : ""
  const slider = homePageNode.acf.top_slider
  const sliderMobile = homePageNode.acf.top_slider_mobile
  const showSlider = slider && slider.length > 0
  const showSliderMobile = sliderMobile && sliderMobile.length > 0
  const liveOnlineCasinos = homePageNode.acf.live_online_casinos
  const liveOnlineCasinosImg = data.live_casino_img.childImageSharp.fluid
  const socialImage = homePageNode.featured_media.source_url
  const stepsGroup = homePageNode.acf.steps_group
  const howToPlay = homePageNode.acf.how_to_play
  const whyChooseUs = homePageNode.acf.why_choose_us
  const agentPromotion = homePageNode.acf.agent_promotion
  const showPopularGames = homePageNode.acf.popular_games.length > 0
  const popularGames = homePageNode.acf.popular_games

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        showSchema={true}
        socialImage={socialImage}
      />
      <div id="home-content-all" className="with-overflow-slider">
        {showSlider && (
          <>
            {isDesktop && (
              <div className="desktop-view">
                <KeenSlider keenSlider={slider} />
              </div>
            )}
            {!isDesktop && showSliderMobile && (
              <div className="mobile-view">
                <KeenSlider keenSlider={sliderMobile} />
              </div>
            )}
          </>
        )}

        <WrapperDesktop>
          {stepsGroup && (
            <>
              {isDesktop ? (
                <StepsNew stepsGroup={stepsGroup} />
              ) : (
                <StepsNewMobile stepsGroup={stepsGroup} />
              )}
            </>
          )}

          {showPopularGames && <PopularGames popular_games={popularGames}/>}

          {liveOnlineCasinos && (
            <LiveOnlineCasinos
              text={liveOnlineCasinos}
              img={liveOnlineCasinosImg}
            />
          )}
        </WrapperDesktop>

        <WrapperDesktop>
          {whyChooseUs && (
            <WhyChooseUs why_choose_us={whyChooseUs} />
          )}

          {howToPlay && (
            <>
              {isDesktop ? (
                <HowToPlay how_to_play={howToPlay} />
              ) : (
                <HowToPlayMobile how_to_play={howToPlay} />
              )}
            </>
          )}

          {agentPromotion && (
            <AgentPromotion agent_promotion={agentPromotion} />
          )}

          {homePageNode.content && (
            <ContentWrapper>
              <div dangerouslySetInnerHTML={{ __html: homePageNode.content }} />
            </ContentWrapper>
          )}
        </WrapperDesktop>
      </div>
    </Layout>
  )
}

export default HomePage

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressPage(filter: { slug: { eq: "home-page" } }) {
      edges {
        node {
          title
          content
          wordpress_id
          featured_media {
            alt_text
            source_url
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
          acf {
            top_slider {
              alt_text
              title
              path
              acf {
                slide_title
                slide_description
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1224) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            top_slider_mobile {
              alt_text
              title
              path
              acf {
                slide_title
                slide_description
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            live_online_casinos
            steps_group {
              steps_block_title
              steps_bottom_description
              steps {
                acf {
                  slide_title
                  slide_description
                }
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 232) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            popular_games {
              alt_text
              title
              id
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 395) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            how_to_play {
              top_title
              how_to_play_item {
                acf {
                  slide_url
                  slide_title
                  slide_description
                }
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            why_choose_us {
              left_column
              right_column
            }
            agent_promotion {
              left_column
              right_column
            }
            popular_games {
              id
            }
          }
        }
      }
    }
    live_casino_img: file(relativePath: { eq: "live-casino-img.png" }) {
      childImageSharp {
        fluid(maxWidth: 352) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
