import React, { memo } from "react";
import styled from "@emotion/styled"
import Img from "gatsby-image"
import  { color } from "../../../utils/style"

const Wrapper = styled("div")`
  .h1 {
    font-size: 43px;
    font-weight: 800;
    line-height: normal;
    letter-spacing: normal;
  }
  .items-wrap {
    display: flex;
    flex-flow: row wrap;
    .item {
      flex-basis: 32%;
      margin: 0 1.8% 1.8% 0;
      background: ${color.transparentBlack2};
      border-radius: 4px;
      &:nth-of-type(3) {
        margin-right: 0;
      }
      .gatsby-image-wrapper {
        border-radius: 4px 4px 0 0;
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        color: ${color.darkGold};
        margin: 8px;
      }
      .description {
        padding: 0 8px 16px;
      }
    }
  }
`

const HowToPlay = ({ how_to_play }) => {
  return (
    <Wrapper>
      <h2 className="h1 rich-design a-center">{how_to_play.top_title}</h2>
      <div className="items-wrap">
        {how_to_play.how_to_play_item.map((item, index) => {
          return (
            <div key={index + Math.random() * 100} className="item">
              <div className="img-wrap">
                {item.localFile && item.localFile.childImageSharp.fluid && (
                  <Img
                    fluid={item.localFile.childImageSharp.fluid}
                    alt={item.altText ? item.altText : "Gclub"}
                    fadeIn={false}
                    loading="eager"
                  />
                )}
              </div>
              {item.acf && item.acf.slide_title && (
                <div>
                  <h3 className="title">{item.acf.slide_title}</h3>
                </div>
              )}
              {item.acf && item.acf.slide_description && (
                <div>
                  <p className="description">{item.acf.slide_description}</p>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </Wrapper>
  )
}

export default memo(HowToPlay)
