import React, { memo, useRef } from "react";
import IcomoonIcon from "../../shared/IcomoonIcon"
import { breakpoints, color } from "../../../utils/style"
import styled from "@emotion/styled"

const Wrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin: 40px 0;
  .col {
    flex-basis: 49%;
    background: ${color.transparentBlack2};
    border-radius: 14px;
    padding: 32px 40px;
  }
  h2 {
    margin: 1rem 0;
    text-align: center;
  }
  img {
    border-radius: 18px;
  }
  p,
  ul,
  pre {
    margin-bottom: 25px;
  }
  ul {
    margin-left: 20px;
  }
  .show-more {
    display: none;
    width: 100%;
    .more-span {
      display: inline;
    }
    .less-span {
      display: none;
    }
    svg {
      margin-left: 10px;
    }
  }
  .show-less {
    width: 100%;
    .more-span {
      display: none;
    }
    .less-span {
      display: inline;
    }
    svg {
      transform: rotate(180deg);
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .col {
      flex-basis: 100%;
      margin-bottom: 24px;
      padding: 24px 16px;
    }
    .long-content-block {
      max-height: 500px;
      overflow: hidden;
      margin-bottom: 20px;
      &.full-height {
        max-height: fit-content;
      }
    }
    .show-more {
      display: block;
    }
  }
`

const AgentPromotion = ({ agent_promotion }) => {
  const handleMoreClick = (e, id, buttonId) => {
    const longContent = document.getElementById(id)
    longContent.classList.toggle("full-height")
    const self = document.getElementById(buttonId)
    self.classList.toggle("show-less")
  }

  const elem1 = useRef(null)
  const elem2 = useRef(null)

  return (
    <Wrapper>
      <div className="col">
        <div
          ref={elem1}
          id="long-promo-block-one"
          className="long-content-block"
          dangerouslySetInnerHTML={{ __html: agent_promotion.left_column }}
        />
        {elem1.current != null && elem1.current.scrollHeight > 520 && (
          <button
            className="button transparent-button show-more"
            id="one-promo-more-less"
            onClick={e =>
              handleMoreClick(e, "long-promo-block-one", "one-promo-more-less")
            }
          >
            <span className="more-span">Read more</span>
            <span className="less-span">Read less</span>
            <IcomoonIcon
              icon="arrow-down-simple"
              color={color.white}
              size={20}
            />
          </button>
        )}
      </div>
      <div className="col">
        <div
          ref={elem2}
          id="long-promo-block-two"
          className="long-content-block"
          dangerouslySetInnerHTML={{ __html: agent_promotion.right_column }}
        />
        {elem2.current != null && elem2.current.scrollHeight > 520 && (
          <button
            className="button transparent-button show-more"
            id="two-promo-more-less"
            onClick={e =>
              handleMoreClick(e, "long-promo-block-two", "two-promo-more-less")
            }
          >
            <span className="more-span">Read more</span>
            <span className="less-span">Read less</span>
            <IcomoonIcon
              icon="arrow-down-simple"
              color={color.white}
              size={20}
            />
          </button>
        )}
      </div>
    </Wrapper>
  )
}

export default memo(AgentPromotion)
