import React, { memo } from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import styled from "@emotion/styled"
import { color, wrapper1200 } from "../../../utils/style"
import Img from "gatsby-image"

const SliderWrap = styled("div")`
  ${wrapper1200}
  position: relative;
  [class^="number-slide"],
  [class*=" number-slide"] {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    border-radius: 24px;
    width: 100%;
    max-width: 100%;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      min-width: 100%;
    }
  }
  .navigation-wrapper {
    position: relative;
  }
  .dots {
    display: flex;
    padding: 12px 0;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }
  .dot {
    border: none;
    width: 8px;
    height: 8px;
    background: ${color.dotsSlider};
    border-radius: 8px;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
    &:hover {
      background: ${color.darkGold};
      border: 2px solid #ff9e00;
    }
  }
  .dot:focus {
    outline: none;
  }
  .dot.active {
    background: ${color.darkGold};
    border: 2px solid #ff9e00;
  }
`

const Wrapper = styled("div")`
  background: ${color.transparentGray};
  border-radius: 14px;
  padding: 24px 0;
  margin-top: 20px;
  .h2 {
    font-size: 21px;
    font-weight: 800;
    color: ${color.darkGold};
  }
  .img-wrap {
    width: 100%;
    max-width: 232px;
    margin: 0 auto;
  }
  .title {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: ${color.darkGold};
    margin: 8px 0 16px;
  }
  .description {
    font-size: 14px;
  }
  .bottom-description {
    margin: 24px 0 50px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: ${color.darkGold};
  }
`

const StepsNewMobile = ({ stepsGroup }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
    loop: true,
    duration: 1000,
    slidesPerView: 1,

  })

  return (
    <Wrapper>
      <h1 className="h2 rich-design a-center">
        {stepsGroup.steps_block_title}
      </h1>
      <SliderWrap>
        <div className="navigation-wrapper">
          <div ref={sliderRef} className="keen-slider">
            {stepsGroup.steps.map((item, index) => {
              return (
                <div
                  className={`keen-slider__slide number-slide${index + 1}`}
                  key={'step' + index}
                >
                  <div className="item">
                    <div className="img-wrap">
                      {item.localFile &&
                        item.localFile.childImageSharp.fluid && (
                          <Img
                            fluid={item.localFile.childImageSharp.fluid}
                            alt={item.altText ? item.altText : "Gclub"}
                            fadeIn={false}
                            loading="eager"
                          />
                        )}
                    </div>
                    <p className="title">{item.acf.slide_title}</p>
                    <div>
                      <p className="description">
                        {item.acf.slide_description}
                      </p>
                    </div>
                    <p className="bottom-description">
                      {stepsGroup.steps_bottom_description}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        {slider && (
          <div className="dots">
            {[...Array(slider.details().size).keys()].map(idx => {
              return (
                <button
                  key={idx}
                  aria-label="Slider dot"
                  name="Slider dot"
                  onClick={() => {
                    slider.moveToSlideRelative(idx)
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                />
              )
            })}
          </div>
        )}
      </SliderWrap>
    </Wrapper>
  )
}

export default memo(StepsNewMobile)
