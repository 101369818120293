import React, { memo } from "react";
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { color } from "../../../utils/style";

const Wrapper = styled('div')`
  background: ${color.transparentGray};
  border-radius: 14px;
  padding: 40px 0;
  margin-top: 40px;
  .h2 {
    font-size: 43px;
    font-weight: 800;
    color: ${color.darkGold};
  }
  .items-wrap {
    display: flex;
    flex-flow: row wrap;
    .item {
      flex-basis: 33.333%;
      position: relative;
      padding: 0 50px;
      &:after {
        content : '';
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0.3;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff 49%, rgba(255, 255, 255, 0));
      }
    }
    .item:last-child {
      &:after {
        display: none;
      }
    }
  }
  .img-wrap {
    width: 100%;
    max-width: 232px;
    margin: 0 auto;
  }
  .title {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: ${color.darkGold};
    margin: 8px 0 16px;
  }
  .description {
    font-size: 14px;
  }
  .bottom-description {
    margin: 24px 50px 0;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: ${color.darkGold};
    white-space: pre-wrap;
  }
`

const StepsNew = ({ stepsGroup }) => {
  return (
    <Wrapper>
      <h1 className="h2 rich-design a-center">{stepsGroup.steps_block_title}</h1>
      <div className="items-wrap">
        {
          stepsGroup.steps.map((item, index) => {
            return (
              <div
                key={index + Math.random() * 100}
                className="item"
              >
                <div className="img-wrap">
                  {item.localFile &&
                  item.localFile.childImageSharp.fluid && (
                    <Img
                      fluid={item.localFile.childImageSharp.fluid}
                      alt={item.altText ? item.altText : 'Gclub'}
                      fadeIn={false} loading='eager'
                    />
                  )}
                </div>
                <p className="title">
                  {item.acf.slide_title}
                </p>
                <div>
                  <p className="description">
                    {item.acf.slide_description}
                  </p>
                </div>
              </div>
            )
          })
        }
      </div>
      <p className="bottom-description">
        {stepsGroup.steps_bottom_description}
      </p>
    </Wrapper>
  )
}

export default memo(StepsNew)