import React, { memo } from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import styled from "@emotion/styled"
import { color } from "../../../utils/style"
import Img from "gatsby-image"

const SliderWrap = styled("div")`
  position: relative;
  [class^="number-slide"],
  [class*=" number-slide"] {
    background: ${color.transparentBlack2};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    border-radius: 24px;
    width: 100%;
    max-width: 100%;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      min-width: 100%;
    }
  }
  .navigation-wrapper {
    position: relative;
  }
  .dots {
    display: flex;
    padding: 12px 0;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }
  .dot {
    border: none;
    width: 8px;
    height: 8px;
    background: ${color.dotsSlider};
    border-radius: 8px;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
    &:hover {
      background: ${color.darkGold};
      border: 2px solid #ff9e00;
    }
  }
  .dot:focus {
    outline: none;
  }
  .dot.active {
    background: ${color.darkGold};
    border: 2px solid #ff9e00;
  }
`

const Wrapper = styled("div")`
  .h1 {
    font-size: 26px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
  }
  .item {
    margin-bottom: 60px;
    .gatsby-image-wrapper {
      border-radius: 4px 4px 0 0;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      color: ${color.darkGold};
      margin: 8px;
    }
    .description {
      padding: 0 8px 16px;
    }
  }
  .img-wrap {
    position: relative;
    .counter {
      background: ${color.darkGold};
      color: ${color.black};
      font-size: 16px;
      font-weight: bold;
      width: fit-content;
      padding: 4px 18px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
`

const HowToPlayMobile = ({ how_to_play }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
    loop: true,
    duration: 1000,
    slidesPerView: 1,
  })

  return (
    <Wrapper>
      <h2 className="h1 rich-design a-center">{how_to_play.top_title}</h2>
      <SliderWrap>
        <div className="navigation-wrapper">
          <div ref={sliderRef} className="keen-slider">
            {how_to_play.how_to_play_item.map((item, index) => {
              return (
                <div
                  className={`keen-slider__slide number-slide${index + 1}`}
                  key={'play' + index}
                >
                  <div className="item">
                    <div className="img-wrap">
                      {item.localFile && item.localFile.childImageSharp.fluid && (
                        <Img
                          fluid={item.localFile.childImageSharp.fluid}
                          alt={item.altText ? item.altText : "Gclub"}
                          fadeIn={false}
                          loading="eager"
                        />
                      )}
                      <div className="counter">
                        <span>
                          {index + 1}/{how_to_play.how_to_play_item.length}
                        </span>
                      </div>
                    </div>
                    {item.acf && item.acf.slide_title && (
                      <div>
                        <h3 className="title">{item.acf.slide_title}</h3>
                      </div>
                    )}
                    {item.acf && item.acf.slide_description && (
                      <div>
                        <p className="description">{item.acf.slide_description}</p>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        {slider && (
          <div className="dots">
            {[...Array(slider.details().size).keys()].map(idx => {
              return (
                <button
                  key={idx}
                  aria-label="Slider dot"
                  name="Slider dot"
                  onClick={() => {
                    slider.moveToSlideRelative(idx)
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                />
              )
            })}
          </div>
        )}
      </SliderWrap>
    </Wrapper>
  )
}

export default memo(HowToPlayMobile)
