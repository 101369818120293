import React, { memo } from "react";
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { breakpoints } from "../../../utils/style"

const SectionWrap = styled("div")`
  margin-bottom: 40px;
  .h1 {
    margin: 72px 0 21px;
  }
  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 30px;
    .h1 {
      margin: 32px 0;
    }
  }
`

const Wrapper = styled("div")`
  display: flex;
  .col-left {
    flex-basis: 68%;
    padding-right: 24px;
  }
  .col-right {
    position: relative;
    flex-basis: 32%;
    .gatsby-image-wrapper {
      max-width: 352px;
      margin: 0 auto;
    }
  }
  .live-casino-text {
    margin: 40px 0 20px;
    span {
      font-size: 14px;
      line-height: 21px;
      white-space: pre-wrap;
    }
  }
  .button {
    margin-right: 16px;
  }
  @media (max-width: ${breakpoints.md}) {
    .col-left {
      flex-basis: 50%;
      padding-right: 0;
    }
    .col-right {
      flex-basis: 50%;
    }
    .live-casino-text {
      margin: 20px 0;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column-reverse;
    .col-left {
      flex-basis: 100%;
    }
    .col-right {
      flex-basis: 100%;
    }
    .button {
      width: 100%;
      display: block;
      margin-bottom: 9px;
    }
  }
`

const LiveOnlineCasinos = ({ text, img }) => {
  return (
    <SectionWrap>
      <h2 className="h1 rich-design a-center">
        <span>รู้จัก Holiday Palace</span>
      </h2>
      <Wrapper>
        <div className="col-left">
          <div className="live-casino-text">
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </div>
          <div className="buttons-group">
            <a
              href={"https://simpson.viva9988.com/"}
              target="_blank"
              rel="noreferrer"
              className="button transparent-button-gold"
            >
              ทางเข้า Holiday 1
            </a>
            <a
              href={"https://simpson.viva9988.com/"}
              target="_blank"
              rel="noreferrer"
              className="button transparent-button-gold"
            >
              ทางเข้า Holiday 2
            </a>
            <a
              href={"https://simpson.viva9988.com/"}
              target="_blank"
              rel="noreferrer"
              className="button transparent-button-gold"
            >
              ทางเข้า Holiday 3
            </a>
            <a
              href={"https://simpson.viva9988.com/"}
              target="_blank"
              rel="noreferrer"
              className="button transparent-button-gold"
            >
              ทางเข้า Holiday 4
            </a>
          </div>
        </div>
        <div className="col-right">
          <Img
            alt={"ถ่ายทอดสดคาสิโนออนไลน์"}
            fluid={img}
            fadeIn={false}
            loading="eager"
          />
        </div>
      </Wrapper>
    </SectionWrap>
  )
}

export default memo(LiveOnlineCasinos);